import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class LanguageService {
  constructor() { }

  getLanguage() {
    const browserLanguages = navigator.languages;
    const cookies = document.cookie.split('; ');

    for (const cookie of cookies) {
      if (cookie.indexOf('lang') !== -1) {
        return cookie.replace('lang=', '');
      }
    }

    for (const lang of browserLanguages) {
      if (lang.indexOf('en') > -1) {
        return 'en';
      }
      if (lang.indexOf('de') > -1) {
        return 'de';
      }
    }

    return 'en';
  }

}
