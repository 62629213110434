import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({providedIn: 'root'})
export class LoginService {
  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
    ) { }

  login(): any {
    this.cookieService.deleteAll(
      '/',
      '',
      true
    );
    this.cookieService.deleteAll(
      '/',
      '',
      false,
      'Strict'
    );
    return this.httpClient.get(environment.loginUrl, {
      headers: {
        'content-type': 'application/json',
        usexbasic: 'true',
        Authorization: `Basic ${environment.basicAuth}`
      }
    });
  }

  setCookies(resp) {
    this.cookieService.set(
      'authorization',
      resp.jwt,
      14,
      '/',
      '',
      false,
      'Strict'
    );
    this.cookieService.set(
      'XSRF-TOKEN',
      resp.xsrf,
      14,
      '/',
      '',
      true
    );
  }
}
