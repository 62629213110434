import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

const LANGUAGE = {
  de: {
    title: 'DE_Please switch to larger screen.',
    firstParagraph: 'DE_This application isn\'t designed for mobile screens due to user-centric design decisions.',
    secondParagraph: 'DE_To get the <strong>full experience</strong>, check the demo from your <strong>laptop, PC or tablet with landscape orientation</strong>.'
  },
  en: {
    title: 'Please switch to larger screen.',
    firstParagraph: 'This application isn\'t designed for mobile screens due to user-centric design decisions.',
    secondParagraph: 'To get the <strong>full experience</strong>, check the demo from your <strong>laptop, PC or tablet with landscape orientation</strong>.'
  }
};

@Component({
  selector: 'app-mobile-warning',
  templateUrl: './mobile-warning.component.html',
  styleUrls: ['./mobile-warning.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobileWarningComponent implements OnInit {
  language;

  constructor(private languageService: LanguageService) { }

  ngOnInit() {
    this.language = {...LANGUAGE[this.languageService.getLanguage()]};
  }
}
