import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { LoginService } from 'src/app/services/login.service';
import { environment } from 'src/environments/environment';

const LANGUAGE = {
  en: {
    errorMessage: 'Error when operating on entity.'
  },
  de: {
    errorMessage: 'Fehler beim Ausführen der Anfrage.'
  }
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hasWarning = false;
  hasError = false;
  errorMessage = '';

  constructor(
    private loginService: LoginService,
    private languageService: LanguageService
    ) { }

  ngOnInit(): void {
    this.hasWarning = window.innerWidth <= 1000;

    if (!this.hasWarning) {
      this.loginService.login().subscribe(
        (response) => {
          if (response.jwt) {
            this.loginService.setCookies(response);
            window.location.href = environment.redirectUrl;
          } else {
            this.toggleError();
          }
        },
        () => this.toggleError()
      );
    }
  }

  toggleError() {
    this.errorMessage = LANGUAGE[this.languageService.getLanguage()].errorMessage;
    this.hasError = true;
  }

}
