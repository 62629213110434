// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // URL were to send the LOGIN request
  loginUrl: 'https://iidemo.iotstg.a1.digital/service/ii-authenticator/api/users/login/guest',
  // Basic Auth to send in request header in login request
  basicAuth: 'aWkucm91dGVyOnZZcVFqZHNFM3JGcA==',
  // Redirect URL to the industrial insight demo iidemo after login
  redirectUrl: 'https://iidemo.iotstg.a1.digital/apps/iidemo/index.html'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
